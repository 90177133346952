import { Button, Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@mui/material";

import React, { useRef, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import MenuBar from "./MenuBar";
import "./styles.scss";

const TipTapEx = () => {
  const editorRef = useRef(null);
  const [textValue, setTextValue] = useState("");
  const handleToPDF = () => {
    setTextValue(editor.getHTML());
  };

  const editor = useEditor({
    extensions: [StarterKit],
    content: "<p></p>",
    onUpdate: ({ editor }) => {
      setTextValue(editor.getHTML());
    },
  });
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <div className="editor">
                {editor && <MenuBar editor={editor} />}
                <EditorContent editor={editor} className="editor__content" />
              </div>
            </CardContent>
            <CardActions>{/* <Button onClick={handleToPDF}>To PDF</Button> */}</CardActions>
          </Card>
          <Typography>Output: {textValue}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TipTapEx;
