import "./App.css";
import { Grid, Card, CardContent, Container, Button, Tabs, Tab, Typography } from "@mui/material";
import DraftToPDF2 from "./components/DraftToPDF2";
import SlatePDF from "./components/SlatePDF";
import Tiny from "./components/Tiny";
import QuillApp from "./components/QuillApp";
import TipTapEx from "./components/TipTap";
import { useState } from "react";
import { Box } from "@mui/system";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function App() {
  const [editorID, setEditorID] = useState(4);

  const buttons = ["DraftToPDF", "Slate", "Tiny", "Quill", "TipTap"];

  const handleTabChange = (event, newValue) => {
    setEditorID(newValue);
  };
  return (
    <Container>
      <Grid item xs={12}>
        <Box>
          <Tabs value={editorID} onChange={handleTabChange}>
            <Tab label="DraftToPDF" />
            <Tab label="Slate" />
            <Tab label="Tiny" />
            <Tab label="Quill" />
            <Tab label="TipTap" />
          </Tabs>
        </Box>
      </Grid>
      <TabPanel value={editorID} index={0}>
        <DraftToPDF2 />
      </TabPanel>

      <TabPanel value={editorID} index={1}>
        <SlatePDF />
      </TabPanel>
      <TabPanel value={editorID} index={2}>
        <Tiny />
      </TabPanel>
      <TabPanel value={editorID} index={3}>
        <QuillApp />
      </TabPanel>
      <TabPanel value={editorID} index={4}>
        <TipTapEx />
      </TabPanel>
    </Container>
  );
}

export default App;
