import { Button, Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState } from "react";

const Tiny = () => {
  const editorRef = useRef(null);
  const [textValue, setTextValue] = useState("");
  const handleToPDF = () => {
    setTextValue(editorRef.current.getContent());
  };
  const handleChange = (e) => {
    setTextValue(e.target.getContent());
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + "/js/tiny/tinymce.min.js"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  menubar: false,
                }}
                onChange={handleChange}
              ></Editor>
            </CardContent>
            <CardActions>{/* <Button onClick={handleToPDF}>To PDF</Button> */}</CardActions>
          </Card>
          <Typography>Output: {textValue}</Typography>
          <hr></hr>
          <Typography>NOTE: Tiny has a big footprint and doesn't update until pressing enter</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Tiny;
