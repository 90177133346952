import { Card, CardContent, Grid } from "@mui/material";
import { convertToRaw } from "draft-js";

import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import draftjsToHtml from "draftjs-to-html";

import React, { useState } from "react";

const DraftToPDF2 = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [textOutput, setTextOutput] = useState("");
  const handleOnChange = (e) => {
    setEditorState(e);
    const raw = convertToRaw(editorState.getCurrentContent());
    const html = draftjsToHtml(raw);
    setTextOutput(html);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Editor editorState={editorState} onChange={handleOnChange} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        Output: {textOutput}
      </Grid>
      <Grid item xs={12} sx={{ borderTop: "1px solid black" }}>
        NOTE: Does not update output with Android keyboard until a space or enter is pressed.
      </Grid>
    </Grid>
  );
};

export default DraftToPDF2;
