import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { createEditor } from "slate";

// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from "slate-react";

const initValue = [{ type: "paragraph", children: [{ text: "" }] }];

const SlatePDF = (props) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [textValue, setTextValue] = useState("");

  const handleChange = (e) => {
    console.log(e);
  };

  const toPDF = () => {};
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Slate editor={editor} value={initValue} onChange={handleChange}>
                <Editable />
              </Slate>
            </CardContent>
            <CardActions>{/* <Button onClick={toPDF}>To PDF</Button> */}</CardActions>
          </Card>
        </Grid>
        <Typography>{textValue}</Typography>
        <Typography>NOTE: Slate has no easy way to output html</Typography>
      </Grid>
    </>
  );
};

export default SlatePDF;
