import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const QuillApp = () => {
  const [textValue, setTextValue] = useState("");

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ReactQuill theme="snow" value={textValue} onChange={setTextValue} />
            </CardContent>
            <CardActions>{/* <Button onClick={() => {}}>To PDF</Button> */}</CardActions>
          </Card>
        </Grid>
        <Typography>Output: {textValue}</Typography>
      </Grid>
    </>
  );
};

export default QuillApp;
